import styled from 'styled-components';

import BREAKPOINTS from 'css/breakpoints';
import { StackedProps } from 'components/types';

export const Wrapper = styled.div<StackedProps>`
  display: flex;
  flex-direction: column;
  width: 100%;
  box-sizing: border-box;

  @media (min-width: ${BREAKPOINTS.md.min}px) {
    flex-direction: ${(props) => (props.stacked ? 'column' : 'row')};
  }
`;
