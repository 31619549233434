import styled, { AnyStyledComponent } from 'styled-components';
import { Coords } from 'google-map-react';

import PinIcon from 'icons/map-pin.svg';

export const Img = styled(PinIcon as AnyStyledComponent)<Coords>`
  height: 55px;
  width: 55px;
  margin-top: -40px;
`;
