import styled, { css, AnyStyledComponent } from 'styled-components';
import Img from 'gatsby-image';

import FONTS from 'css/fonts';
import COLORS from 'css/colors';
import BREAKPOINTS from 'css/breakpoints';
import { Cell } from 'components/common';
import { StackedProps } from 'components/types';
import FBIcon from 'icons/facebook.svg';
import PhoneIcon from 'icons/telephone.svg';
import LetterIcon from 'icons/envelope.svg';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 1;

  @media (min-width: ${BREAKPOINTS.md.min}px) {
    flex-direction: row;
    align-items: flex-start;
  }
`;

export const Company = styled.p`
  font-family: ${FONTS.primary};
  font-size: 22px;
  text-transform: uppercase;
  font-weight: 600;
  margin-top: 0;
  margin-bottom: 20px;
  color: ${COLORS.red};
`;

export const P = styled.p`
  font-family: ${FONTS.primary};
  margin: 10px 0 0 0;
`;

export const IconStyles = css`
  display: block;
  height: 24px;
  width: 24px;
  margin-right: 15px;
  transition: all 0.3s ease-out;

  path {
    transition: all 0.3s ease-out;
  }

  @media (min-width: ${BREAKPOINTS.md.min}px) {
    height: 30px;
    width: 30px;
  }
`;

export const FacebookIcon = styled(FBIcon as AnyStyledComponent)`
  ${IconStyles}
`;

export const TelephoneIcon = styled(PhoneIcon as AnyStyledComponent)`
  ${IconStyles}
`;

export const EnvelopeIcon = styled(LetterIcon as AnyStyledComponent)`
  ${IconStyles}
`;

export const BoxWithIcon = styled.div`
  display: flex;
  align-items: center;
  margin-top: 20px;

  &:hover {
    ${FacebookIcon} {
      transform: scale(1.1);

      path {
        fill: ${COLORS.facebook};
      }
    }
    ${TelephoneIcon} {
      transform: scale(1.1);

      path {
        fill: ${COLORS.green};
      }
    }
    ${EnvelopeIcon} {
      transform: scale(1.1);

      path {
        fill: ${COLORS.babyBlue};
      }
    }
  }
`;

export const ContactCell = styled(Cell)<StackedProps>`
  display: flex;
  flex-direction: column;
  margin-bottom: 30px;
  align-items: center;

  ${BoxWithIcon}:first-of-type {
    margin-top: 50px;
  }

  @media (min-width: ${BREAKPOINTS.md.min}px) {
    margin-bottom: ${(props) => (props.stacked ? '50px' : '0')};
    align-items: flex-start;
  }
`;

export const ContactText = styled.p`
  font-family: ${FONTS.primary};
  font-size: 14px;
  font-weight: 600;
  flex-wrap: wrap;
  margin: 0;
`;

export const Image = styled(Img)`
  width: 290px;

  @media (min-width: ${BREAKPOINTS.md.min}px) {
    width: 390px;
  }
`;

export const StyledCell = styled(Cell)`
  margin-bottom: 30px;

  @media (min-width: ${BREAKPOINTS.md.min}px) {
    margin-bottom: 0;
  }
`;

export const StyledA = styled.a`
  display: block;
  cursor: pointer;
  text-decoration: none;
  color: ${COLORS.pureBlack};
`;
