import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';

import { StackedProps } from 'components/types';
import {
  Wrapper,
  ContactCell,
  BoxWithIcon,
  Company,
  P,
  ContactText,
  Image,
  StyledCell,
  StyledA,
  FacebookIcon,
  TelephoneIcon,
  EnvelopeIcon,
} from './ContactComponentStyled';

const ContactComponent: React.FC<StackedProps> = ({ stacked }) => {
  const { logo, markdownRemark } = useStaticQuery(
    graphql`
      query LogoQuery {
        markdownRemark(fileAbsolutePath: { regex: "/contact/" }) {
          frontmatter {
            contact_city
            contact_company
            contact_email
            contact_facebook
            contact_phone_1
            contact_postal_code
            contact_street
          }
        }
        logo: file(relativePath: { eq: "logo_dark.png" }) {
          childImageSharp {
            fluid(quality: 100) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    `
  );
  const {
    contact_city,
    contact_company,
    contact_email,
    contact_facebook,
    contact_phone_1,
    contact_postal_code,
    contact_street,
  } = markdownRemark.frontmatter;

  return (
    <Wrapper>
      <ContactCell stacked={stacked}>
        <Company>{contact_company}</Company>
        <P>{contact_street}</P>
        <P>{`${contact_postal_code} ${contact_city}`}</P>
        <BoxWithIcon>
          <TelephoneIcon />
          <ContactText>
            <StyledA
              href={`tel:${contact_phone_1}`}
              title="Motoroma - Zadzwoń do nas"
            >
              {contact_phone_1}
            </StyledA>
          </ContactText>
        </BoxWithIcon>
        <BoxWithIcon>
          <EnvelopeIcon />
          <ContactText>
            <StyledA
              href={`mailto:${contact_email}`}
              title="Motoroma - Napisz do nas"
            >
              {contact_email}
            </StyledA>
          </ContactText>
        </BoxWithIcon>
        <BoxWithIcon>
          <FacebookIcon />
          <ContactText>
            <StyledA
              href={contact_facebook}
              target="_blank"
              rel="noopener noreferrer"
              title="Motoroma - Odwiedź nas na Facebook"
            >
              Motoroma Facebook
            </StyledA>
          </ContactText>
        </BoxWithIcon>
      </ContactCell>
      {stacked && (
        <StyledCell>
          <Image
            fluid={logo.childImageSharp.fluid}
            alt="Motoroma Serwis logo"
          />
        </StyledCell>
      )}
    </Wrapper>
  );
};

export default ContactComponent;
