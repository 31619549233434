import React, { useState, useEffect } from 'react';
import GoogleMapReact from 'google-map-react';
import { graphql, useStaticQuery } from 'gatsby';

import { Img } from './MapComponentStyled';
import { Cell } from 'components/common';

const MapComponent = () => {
  const [isClientLoaded, setLoaded] = useState(false);
  useEffect(() => {
    if (typeof window !== 'undefined') {
      setLoaded(true);
    }
  });

  const { locationData } = useStaticQuery(
    graphql`
      query LocationQuery {
        locationData {
          results {
            geometry {
              location {
                lat
                lng
              }
            }
          }
        }
      }
    `
  );
  const coords = locationData.results[0].geometry.location;

  return (
    <Cell>
      {isClientLoaded && (
        <GoogleMapReact
          bootstrapURLKeys={{ key: process.env.GOOGLE_API_KEY as string }}
          defaultCenter={{ lat: coords.lat, lng: coords.lng }}
          defaultZoom={12}
        >
          <Img lat={coords.lat} lng={coords.lng} alt="Mapa google" />
        </GoogleMapReact>
      )}
    </Cell>
  );
};

export default MapComponent;
