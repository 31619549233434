import React from 'react';

import Section from 'components/Section';
import { Wrapper } from './ContactStyled';
import ContactComponent from 'components/ContactComponent';
import MapComponent from 'components/MapComponent';

import { StackedProps, WithoutHeaderProps } from 'components/types';

const Contact: React.FC<WithoutHeaderProps & StackedProps> = ({
  withoutHeader,
  stacked,
}) => (
  <Section header="Kontakt" $noButton withoutHeader={withoutHeader}>
    <Wrapper stacked={stacked}>
      <ContactComponent stacked={stacked} />
      <MapComponent />
    </Wrapper>
  </Section>
);

export default Contact;
